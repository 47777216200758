import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Translation, TranslationKey } from "../../models/Translation";
import useDebounce from "../../utils/hooks";

type TranslationCardProps = {
  newKey: TranslationKey;
  selected: TranslationKey[];
  allSelected: boolean;
  toggleSelected: (i: number) => (e) => void;
  i: number;
  projectBaseLanguage: Translation;
  projectBaseLanguageKeys: TranslationKey[];
  isBaseLanguage: boolean;
  handleBaseLanguageKeysValue: (keyId: string) => string | null;
  lang: string | undefined;
  edit: boolean[];
  keyToView: (key: TranslationKey) => string;
  handleDraftEditing: (draft: string, key: TranslationKey, i: number) => void;
  setEdit: (value: SetStateAction<boolean[]>) => void;
  projectLanguageKeys: TranslationKey[];
  edited: boolean[] | undefined;
  setEdited: Dispatch<SetStateAction<boolean[] | undefined>>;
  setProjectLanguageKeys: Dispatch<SetStateAction<TranslationKey[]>>;
  setDisplayProjectLanguageKeys: Dispatch<SetStateAction<TranslationKey[]>>;
  originalKeys: TranslationKey[];
};

function TranslationCard({
  newKey,
  selected,
  allSelected,
  toggleSelected,
  i,
  projectBaseLanguage,
  projectBaseLanguageKeys,
  isBaseLanguage,
  handleBaseLanguageKeysValue,
  lang,
  edit,
  keyToView,
  handleDraftEditing,
  setEdit,
  projectLanguageKeys,
  edited,
  setEdited,
  setProjectLanguageKeys,
  setDisplayProjectLanguageKeys,
  originalKeys,
}: TranslationCardProps) {
  const [draft, setDraft] = useState(keyToView(newKey) || "");
  const [changing, setChanging] = useState(false);
  const [debounceVal, setDebounceVal] = useState("");

  const debounceValue = useDebounce(draft, 500);

  useEffect(() => {
    if (changing) {
      const newState = projectLanguageKeys.map((obj) => {
        if (edited) {
          const newEditedState = edited.map((o, index) => {
            if (i === index && !o) {
              return true;
            }

            return o;
          });
          setEdited(newEditedState);
        }
        if (obj.keyId === newKey.keyId) {
          return { ...obj, draft };
        }

        return obj;
      });

      setProjectLanguageKeys(newState);
      setDisplayProjectLanguageKeys(newState);
      setDebounceVal(draft);
      setChanging(false);
    }
  }, [debounceValue]);

  const handleChange = (e) => {
    setChanging(true);
    setDraft(e.target.value);
  };

  return (
    <Card
      sx={{
        borderRadius: "10px",
        boxShadow: "0px 0px 12px 0px rgba(55, 73, 72, 0.06)",
        mb: 2,
      }}
      key={newKey.keyId}
    >
      <CardContent>
        <Grid container sx={{ placeItems: "center" }}>
          <Grid item xs={6} sx={{ display: "grid" }}>
            <Box sx={{ display: "inline-flex" }}>
              <Checkbox
                checked={
                  selected.findIndex((x) => x.keyId === newKey.keyId) !== -1 ||
                  allSelected
                }
                onChange={toggleSelected(i)}
                sx={{ mr: 1 }}
              />
              <Typography sx={{ mt: 1 }}>{newKey.keyId}</Typography>
            </Box>
            {projectBaseLanguage &&
              projectBaseLanguageKeys &&
              projectBaseLanguageKeys.length &&
              !isBaseLanguage && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ mb: 0.5, mr: 2 }}>
                    {projectBaseLanguage.languageCode}
                  </Typography>
                  <TextField
                    disabled
                    variant="filled"
                    label={t("translations.base-language-value")}
                    value={handleBaseLanguageKeysValue(newKey.keyId)}
                    sx={{ mb: 1, width: "50%" }}
                  />
                </Box>
              )}
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "center" }}>
            <Typography>{lang}</Typography>
          </Grid>
          <Grid item xs={5}>
            {newKey.draft !== null || edit[i] ? (
              <Box sx={{ display: "flex" }}>
                <TextField
                  fullWidth
                  multiline
                  className="value-field"
                  variant="filled"
                  label={t("translations.draft-value")}
                  value={draft}
                  onChange={handleChange}
                  sx={{ mb: 1, zIndex: 0 }}
                  InputProps={{
                    endAdornment: (
                      <Button
                        disabled={
                          // eslint-disable-next-line no-nested-ternary
                          originalKeys[i].draft
                            ? originalKeys[i].draft === draft
                            : originalKeys[i].published
                            ? originalKeys[i].published === draft
                            : false
                        }
                        variant="contained"
                        className="value-field-button"
                        sx={{ minHeight: "56px", height: "100%" }}
                        onClick={() => {
                          handleDraftEditing(draft, newKey, i);
                        }}
                      >
                        <SaveIcon />
                      </Button>
                    ),
                  }}
                />
                <CircleIcon
                  sx={{ ml: 2, alignSelf: "center" }}
                  style={{
                    color: draft !== newKey.published ? "#FFC100" : "#455A64",
                  }}
                />
              </Box>
            ) : null}
            <Box sx={{ display: "flex" }}>
              <TextField
                fullWidth
                disabled
                className="value-field"
                variant="filled"
                label={t("translations.published-value")}
                value={newKey.published ? newKey.published : ""}
                sx={{ zIndex: 0 }}
                InputProps={{
                  endAdornment: (
                    <Button
                      type="submit"
                      variant="contained"
                      className="value-field-button"
                      sx={{ minHeight: "56px" }}
                      onClick={() => {
                        const newState = edit.map((obj, index) => {
                          if (i === index && !obj) {
                            return true;
                          }
                          if (i === index && obj) {
                            newKey.draft = null;
                            return false;
                          }

                          return obj;
                        });
                        setEdit(newState);
                      }}
                    >
                      {edit[i] ? <UndoIcon /> : <EditIcon />}
                    </Button>
                  ),
                }}
              />
              <CircleIcon
                sx={{ alignSelf: "center", ml: 2 }}
                style={{ color: "#71C02B" }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TranslationCard;
