/* eslint-disable no-nested-ternary */
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PublishIcon from "@mui/icons-material/Publish";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Checkbox,
  Fab,
  Grid,
  Input,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Lottie from "lottie-react";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import noElementsIllustration from "../../assets/animations/no-elements-animation.json";
import NewTranslationCard from "../../components/NewTranslationCard/NewTranslationCard";
import TranslationCard from "../../components/TranslationCard/TranslationCard";
import { Project } from "../../models/Project";
import { Translation, TranslationKey } from "../../models/Translation";
import { get, post, put } from "../../services/Api/ApiFunctions";
import useDebounce from "../../utils/hooks";
import "./TranslationsList.css";

function TranslationsList() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { id, lang } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [project, setProject] = useState<Project>();
  const [projectLanguage, setProjectLanguage] = useState<
    Translation | undefined
  >(undefined);
  const [projectBaseLanguage, setProjectBaseLanguage] = useState<Translation>();
  const [projectBaseLanguageKeys, setProjectBaseLanguageKeys] = useState<
    TranslationKey[]
  >([]);
  const [projectLanguageKeys, setProjectLanguageKeys] = useState<
    TranslationKey[]
  >([]);
  const [displayProjectLanguageKeys, setDisplayProjectLanguageKeys] = useState<
    TranslationKey[]
  >([]);
  const [newProjectLanguageKeys, setNewProjectLanguageKeys] = useState<
    TranslationKey[]
  >([]);
  const [anchorElImport, setAnchorElImport] = useState<null | HTMLElement>(
    null
  );
  const openImport = Boolean(anchorElImport);
  const handleClickImport = (event: MouseEvent<HTMLElement>) => {
    setAnchorElImport(event.currentTarget);
  };
  const handleCloseImport = () => {
    setAnchorElImport(null);
  };
  const [update, setUpdate] = useState(true);
  const [allSelected, setAllSelected] = useState(false);
  const [selected, setSelected] = useState<TranslationKey[]>([]);
  const [selectedNew, setSelectedNew] = useState<TranslationKey[]>([]);
  const [edit, setEdit] = useState<boolean[]>([]);
  const [edited, setEdited] = useState<boolean[]>();
  const [isBaseLanguage, setIsBaseLanguage] = useState(false);
  const [importFile, setImportFile] = useState();
  const [shiftOn, setShiftOn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [originalKeys, setOriginalKeys] = useState<TranslationKey[]>([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.addEventListener("keydown", (e) => {
      if (e.key === "Shift") {
        e.preventDefault();
        setShiftOn(true);
      }
    });
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", (e) => {
      if (e.key === "Shift") {
        e.preventDefault();
        setShiftOn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (id && update) {
      get<Project>(`/projects/${id}`)
        .then((res) => {
          setProject(res.data);
          get<Translation>(`/projects/${id}/translations/${lang}`)
            .then((response) => {
              setProjectLanguage(response.data);
              const projectKeys = response.data.keys.sort((a, b) => {
                if (!a.published && b.published) return -1;
                if (a.published && !b.published) return 1;

                const aHasBoth = !!a.published && !!a.draft;
                const bHasBoth = !!b.published && !!b.draft;
                if (aHasBoth && !bHasBoth) return -1;
                if (!aHasBoth && bHasBoth) return 1;

                return a.keyId.localeCompare(b.keyId);
              });
              setOriginalKeys(projectKeys);
              setProjectLanguageKeys(projectKeys);
              setDisplayProjectLanguageKeys(projectKeys);
              setEdit(new Array(response.data.keys.length).fill(false));
              setEdited(new Array(response.data.keys.length).fill(false));
              get<Translation>(`/projects/${id}/translations/base-language`)
                .then((r) => {
                  if (r.data.languageCode === lang) {
                    setIsBaseLanguage(true);
                  }
                  setProjectBaseLanguage(r.data);
                  const projectBaseKeys = r.data.keys.sort((a, b) => {
                    if (!a.published && b.published) return -1;
                    if (a.published && !b.published) return 1;

                    const aHasBoth = !!a.published && !!a.draft;
                    const bHasBoth = !!b.published && !!b.draft;
                    if (aHasBoth && !bHasBoth) return -1;
                    if (!aHasBoth && bHasBoth) return 1;

                    return a.keyId.localeCompare(b.keyId);
                  });
                  setProjectBaseLanguageKeys(projectBaseKeys);
                  setUpdate(false);
                  setIsLoading(false);
                })
                .catch(() => {
                  setUpdate(false);
                  setIsLoading(false);
                  toast.error(t("errors.generic-error"));
                });
            })
            .catch(() => {
              setUpdate(false);
              setIsLoading(false);
              toast.error(t("errors.generic-error"));
            });
        })
        .catch(() => {
          setUpdate(false);
          setIsLoading(false);
          toast.error(t("errors.generic-error"));
        });
    }
  }, [id, update]);

  const toggleAllSelected = (e) => {
    const { checked } = e.target;
    setAllSelected(checked);

    if (checked) {
      setSelected(projectLanguageKeys);
      setSelectedNew(newProjectLanguageKeys);
    } else {
      setSelected([]);
      setSelectedNew([]);
    }
  };

  const toggleSelected = (i: number) => (e) => {
    if (!e.target.checked) {
      setAllSelected(false);
    }

    if (shiftOn) {
      let beginAdding = -1;
      for (let j = 0; j < i; j += 1) {
        if (
          selected.findIndex(
            (x) => x.keyId === displayProjectLanguageKeys[j].keyId
          ) !== -1
        ) {
          beginAdding = j;
          break;
        }
      }
      if (beginAdding !== -1) {
        const keysToAdd: TranslationKey[] = [];
        for (let z = beginAdding + 1; z <= i; z += 1) {
          keysToAdd.push(displayProjectLanguageKeys[z]);
        }
        setSelected((current) => [...current, ...keysToAdd]);
      }
    } else if (
      !selected.find((x) => x.keyId === displayProjectLanguageKeys[i].keyId)
    ) {
      setSelected([...selected, displayProjectLanguageKeys[i]]);
    } else {
      const newSelected = selected.filter(
        (x) => x.keyId !== displayProjectLanguageKeys[i].keyId
      );
      setSelected(newSelected);
    }
  };

  const toggleSelectedNew = (i: number) => (e) => {
    if (!e.target.checked) {
      setAllSelected(false);
    }

    if (!selectedNew.find((x) => x.keyId === newProjectLanguageKeys[i].keyId)) {
      setSelectedNew([...selectedNew, newProjectLanguageKeys[i]]);
    } else {
      const newSelected = selectedNew.filter(
        (x) => x.keyId !== newProjectLanguageKeys[i].keyId
      );
      setSelectedNew(newSelected);
    }
  };

  const handleDraftSave = (
    keys: TranslationKey[],
    newState?: TranslationKey[],
    newKey = false
  ) => {
    if (projectLanguage && keys) {
      const keysArray = keys.map(({ draft, keyId }) => ({
        value: !newState
          ? draft ===
              projectLanguageKeys.find((x) => x.keyId === keyId)?.draft ||
            newKey
            ? draft?.trim()
            : projectLanguageKeys.find((x) => x.keyId === keyId)
            ? projectLanguageKeys.find((x) => x.keyId === keyId)?.draft?.trim()
            : draft?.trim()
          : newState.find((x) => x.keyId === keyId)?.draft?.trim(),
        keyId,
        status: "in",
      }));
      const keysObj = {
        keys: keysArray,
        version: projectLanguage.version,
      };
      put(`/projects/${id}/translations/${lang}/drafts`, keysObj)
        .then(() => {
          setProjectLanguage(undefined);
          setProjectLanguageKeys([]);
          setDisplayProjectLanguageKeys([]);
          setNewProjectLanguageKeys([]);
          setAllSelected(false);
          setSelected([]);
          setSelectedNew([]);
          setUpdate(true);
          toast.success(t("translations.draft-saved"));
        })
        .catch(() => {
          toast.error(t("errors.generic-error"));
        });
    }
  };

  const handleDeletePending = (index: number) => {
    newProjectLanguageKeys.splice(index, 1);
    setNewProjectLanguageKeys([...newProjectLanguageKeys]);
  };

  const handlePublish = (keys: TranslationKey[]) => {
    if (projectLanguage && keys) {
      const keysArray = keys.map(({ draft, keyId }) => ({
        value: draft?.trim(),
        keyId,
        status: "in",
      }));
      const keysObj = {
        keys: keysArray,
        version: projectLanguage.version,
      };
      put(`/projects/${id}/translations/${lang}/published`, keysObj)
        .then(() => {
          setProjectLanguage(undefined);
          setProjectLanguageKeys([]);
          setDisplayProjectLanguageKeys([]);
          setNewProjectLanguageKeys([]);
          setAllSelected(false);
          setSelected([]);
          setSelectedNew([]);
          setUpdate(true);
          toast.success(t("translations.published"));
        })
        .catch(() => {
          toast.error(t("errors.generic-error"));
        });
    }
  };

  const handleNewTranslation = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setNewProjectLanguageKeys([
      ...newProjectLanguageKeys,
      { keyId: "", draft: "", published: null },
    ]);
  };

  const handleDeleteKey = (keys: TranslationKey[]) => {
    if (projectLanguage && keys) {
      const keysArray = keys.map(({ keyId }) => ({
        value: null,
        keyId,
        status: "out",
      }));
      const keysObj = {
        keys: keysArray,
        version: projectLanguage.version,
      };
      put(`/projects/${id}/translations/${lang}/published`, keysObj)
        .then(() => {
          toast.success(t("translations.key-deleted"));
          setAllSelected(false);
          setSelected([]);
          setSelectedNew([]);
          setUpdate(true);
        })
        .catch(() => {
          toast.error(t("errors.generic-error"));
        });
    }
  };

  const handleDeleteDrafts = (keys: TranslationKey[]) => {
    if (projectLanguage && keys) {
      const keysArray = keys.map(({ keyId }) => ({
        value: null,
        keyId,
        status: "in",
      }));
      const keysObj = {
        keys: keysArray,
        version: projectLanguage.version,
      };
      put(`/projects/${id}/translations/${lang}/drafts`, keysObj)
        .then(() => {
          toast.success(t("translations.draft-cleared"));
          setAllSelected(false);
          setSelected([]);
          setSelectedNew([]);
          setUpdate(true);
        })
        .catch(() => {
          toast.error(t("errors.generic-error"));
        });
    }
  };

  const handleBaseLanguageKeysValue = (keyId: string) => {
    if (
      projectBaseLanguageKeys &&
      projectBaseLanguageKeys.findIndex(
        (key: TranslationKey) => key.keyId === keyId
      ) === -1
    ) {
      return "";
    }
    if (
      projectBaseLanguageKeys &&
      projectBaseLanguageKeys.findIndex(
        (key: TranslationKey) => key.keyId === keyId
      ) !== -1
    ) {
      const index = projectBaseLanguageKeys.findIndex(
        (key: TranslationKey) => key.keyId === keyId
      );
      if (projectBaseLanguageKeys[index].published) {
        return projectBaseLanguageKeys[index].published;
      }
      if (projectBaseLanguageKeys[index].draft) {
        return projectBaseLanguageKeys[index].draft;
      }
    }
    return "";
  };

  const handleLanguageView = () => {
    if (project && lang) {
      const label = project.languages.find((x) => x.code === lang)?.label;
      if (label) {
        return label;
      }
      return project.languages.find((x) => x.code === lang)?.code;
    }
    return t("languages.language");
  };

  useEffect(() => {
    if (importFile) {
      const formData = new FormData();
      formData.append("file", importFile);
      post(`/projects/${id}/translations/import?langCode=${lang}`, formData)
        .then(() => {
          handleCloseImport();
          setImportFile(undefined);
          toast.success(t("translations.imported-successfully"));
          setUpdate(true);
        })
        .catch(() => {
          setImportFile(undefined);
          toast.error(t("translations.imported-error"));
        });
    }
  }, [importFile]);

  const handleTranslationsImport = (event) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      setImportFile(file);
    }
  };

  const keyToView = (key: TranslationKey) => {
    if (key.draft !== "") {
      if (key.draft) {
        return key.draft;
      }
      if (key.published) {
        return key.published;
      }
      return "";
    }
    return "";
  };

  const handleDraftEditing = (
    draft: string,
    key: TranslationKey,
    i: number
  ) => {
    const newState = projectLanguageKeys.map((obj) => {
      if (edited) {
        const newEditedState = edited.map((o, index) => {
          if (i === index && !o) {
            return true;
          }

          return o;
        });
        setEdited(newEditedState);
      }
      if (obj.keyId === key.keyId) {
        return { ...obj, draft };
      }

      return obj;
    });

    setProjectLanguageKeys(newState);
    setDisplayProjectLanguageKeys(newState);

    handleDraftSave(
      [
        {
          keyId: key.keyId,
          draft,
          published: key.published,
        },
      ],
      newState
    );
  };

  const selectedCount =
    Object.values(selected).filter(Boolean).length +
    Object.values(selectedNew).filter(Boolean).length;

  const isAllSelected =
    selectedCount ===
    projectLanguageKeys.length + newProjectLanguageKeys.length;
  const isIndeterminate =
    selectedCount &&
    selectedCount !==
      projectLanguageKeys.length + newProjectLanguageKeys.length;

  const [searchVal, setSearchVal] = useState("");
  const [debounceVal, setDebounceVal] = useState("");

  const debounceValue = useDebounce(searchVal, 500);

  useEffect(() => {
    const filtered = projectLanguageKeys.filter((key) => {
      const value = handleBaseLanguageKeysValue(key.keyId);
      return (
        value?.toLowerCase().includes(searchVal.toLowerCase()) ||
        key.keyId.toLowerCase().includes(searchVal.toLowerCase())
      );
    });
    setDisplayProjectLanguageKeys(filtered);
    setDebounceVal(searchVal);
  }, [debounceValue]);

  const handleChange = (e) => {
    setDisplayProjectLanguageKeys([]);
    setSearchVal(e.target.value);
  };

  return (
    <>
      {/* HEADER */}
      <Box
        sx={{
          position: "fixed",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 0px 25px 0px rgba(45, 69, 95, 0.1)",
          width: "100%",
          pt: "5rem",
          zIndex: 1000,
        }}
      >
        <Grid container sx={{ mb: 2, pl: 2, pr: 5 }}>
          <Grid
            item
            md={9.5}
            lg={9.5}
            xs={12}
            sx={{
              alignSelf: "center",
              mb: isMobile ? 2 : 0,
              display: "inline-flex",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderRadius: "25px",
                backgroundColor: "white",
                minWidth: "40px",
                width: "40px",
                height: "40px",
                mr: 1,
              }}
              onClick={() => navigate(`/projects/${id}`)}
            >
              <ChevronLeftIcon />
            </Button>
            <Typography variant="h5" sx={{ mt: 0.75 }}>
              {project ? project.name : t("languages.project")} -&gt;{" "}
              {t("languages.languages-list")} -&gt; {handleLanguageView()}
            </Typography>
          </Grid>
          <Grid item md={2.5} lg={2.5} xs={12}>
            <TextField
              sx={{ width: "100%" }}
              id="outlined-search"
              label={t("languages.search")}
              type="search"
              value={searchVal}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            mb: 2,
            pr: 4.5,
          }}
        >
          {/* SELECT ALL */}
          <Grid item lg={1.2} md={2} sx={{ px: 1 }}>
            <Checkbox
              id="select-all-checkbox"
              checked={allSelected || isAllSelected}
              onChange={toggleAllSelected}
              indeterminate={!!isIndeterminate}
              inputProps={{ "aria-label": "select all keys" }}
              sx={{}}
            />
            <label htmlFor="select-all-checkbox" style={{ fontWeight: "500" }}>
              {t("translations.select-all")}
            </label>
          </Grid>

          {/* PUBLISH */}
          <Grid item lg={1} md={1} sx={{ px: 1, textAlign: "center" }}>
            {selectedCount > 0 && (
              <Button
                variant="contained"
                color="success"
                sx={{ color: "secondary.main", width: "100%" }}
                id="publish-button"
                aria-controls={openImport ? "import-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openImport ? "true" : undefined}
                onClick={() => {
                  console.log("selected", selected);
                  const filteredSelected = selected.filter(
                    (x) => x.draft !== null && x.draft !== undefined
                  );
                  handlePublish(filteredSelected);
                }}
                disableElevation
                endIcon={<PublishIcon />}
              >
                {t("translations.publish")}
              </Button>
            )}
          </Grid>

          {/* SAVE DRAFTS */}
          <Grid item lg={1.5} md={1.5} sx={{ px: 1, textAlign: "center" }}>
            {selectedCount > 0 && (
              <Button
                variant="contained"
                sx={{ color: "secondary.main", width: "100%" }}
                id="save-drafts-button"
                aria-controls={openImport ? "import-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openImport ? "true" : undefined}
                disableElevation
                onClick={() => {
                  const filteredSelectedNew = selectedNew.filter(
                    (x) => x.keyId
                  );
                  const filteredSelected = selected.filter(
                    (x) => x.draft !== null && x.draft !== undefined
                  );
                  if (selectedNew) {
                    handleDraftSave([
                      ...filteredSelected,
                      ...filteredSelectedNew,
                    ]);
                  } else {
                    handleDraftSave(filteredSelected);
                  }
                  handleClose();
                }}
                endIcon={<SaveIcon />}
              >
                {t("translations.save-drafts")}
              </Button>
            )}
          </Grid>

          {/* CLEAR DRAFTS */}
          <Grid item lg={1.5} md={1.5} sx={{ px: 1, textAlign: "center" }}>
            {selectedCount > 0 && (
              <Button
                variant="contained"
                color="warning"
                sx={{ width: "100%" }}
                id="clear-drafts-button"
                aria-controls={openImport ? "import-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openImport ? "true" : undefined}
                disableElevation
                onClick={() => {
                  const filteredSelected = selected.filter(
                    (x) => x.draft !== null && x.draft !== undefined
                  );
                  handleDeleteDrafts(filteredSelected);
                }}
                endIcon={<ClearAllIcon />}
              >
                {t("translations.clear-drafts")}
              </Button>
            )}
          </Grid>
          {/* DELETE */}
          <Grid item lg={1} md={1} sx={{ px: 1, textAlign: "center" }}>
            {selectedCount > 0 && (
              <Button
                variant="contained"
                color="error"
                sx={{ color: "secondary.main", width: "100%" }}
                id="save-drafts-button"
                aria-controls={openImport ? "import-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openImport ? "true" : undefined}
                disableElevation
                onClick={() => {
                  const filteredSelected = selected.filter(
                    (x) =>
                      (x.draft !== null && x.draft !== undefined) ||
                      (x.published !== null && x.published !== undefined)
                  );
                  handleDeleteKey(filteredSelected);
                  handleClose();
                }}
                endIcon={<DeleteIcon />}
              >
                {t("translations.delete")}
              </Button>
            )}
          </Grid>
          {/* SPACING */}
          <Grid item md={3} lg={4.3} />
          {/* IMPORT */}
          <Grid item lg={1} md={1} sx={{ px: 1 }}>
            <Button
              variant="contained"
              sx={{ color: "secondary.main", width: "100%" }}
              onClick={handleClickImport}
              id="import-button"
              aria-controls={openImport ? "import-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openImport ? "true" : undefined}
              disableElevation
              endIcon={<KeyboardArrowDownIcon />}
            >
              {t("translations.import")}
            </Button>
            <Menu
              id="import-menu"
              MenuListProps={{
                "aria-labelledby": "import-button",
              }}
              anchorEl={anchorElImport}
              open={openImport}
              onClose={handleCloseImport}
              elevation={0}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <label htmlFor="json-upload-file">
                <Input
                  inputProps={{ accept: "application/json" }}
                  id="json-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>JSON</MenuItem>
              </label>
              <label htmlFor="csv-upload-file">
                <Input
                  inputProps={{ accept: "text/csv" }}
                  id="csv-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>CSV</MenuItem>
              </label>
              <label htmlFor="xml-upload-file">
                <Input
                  inputProps={{ accept: "application/xml" }}
                  id="xml-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>XML</MenuItem>
              </label>
              <label htmlFor="strings-upload-file">
                <Input
                  inputProps={{ accept: "text/strings" }}
                  id="strings-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>STRINGS</MenuItem>
              </label>
              <label htmlFor="xliff-upload-file">
                <Input
                  inputProps={{ accept: " application/xliff+xml" }}
                  id="xliff-upload-file"
                  type="file"
                  onChange={(e) => handleTranslationsImport(e)}
                  style={{ display: "none" }}
                />{" "}
                <MenuItem disableRipple>XLIFF</MenuItem>
              </label>
            </Menu>
          </Grid>
          {/* ADD */}
          <Grid item lg={0.5} md={0.5} sx={{ px: 1 }}>
            <Button
              color="primary"
              sx={{ color: "secondary.main", width: "100%" }}
              aria-label="add"
              onClick={() => handleNewTranslation()}
              variant="contained"
              id="add-button"
              disableElevation
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* TRANSLATIONS LIST */}
      <Box
        sx={{ width: "95%", margin: "14rem auto" }}
        className="layoutMinHeight"
      >
        {newProjectLanguageKeys &&
          newProjectLanguageKeys.map((newKey, i) => (
            <NewTranslationCard
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              i={i}
              selectedNew={selectedNew}
              newKey={newKey}
              allSelected={allSelected}
              toggleSelectedNew={toggleSelectedNew}
              newProjectLanguageKeys={newProjectLanguageKeys}
              setNewProjectLanguageKeys={setNewProjectLanguageKeys}
              handleDraftSave={handleDraftSave}
              handleDeletePending={handleDeletePending}
            />
          ))}
        {projectBaseLanguage &&
          projectBaseLanguageKeys &&
          displayProjectLanguageKeys &&
          displayProjectLanguageKeys.map((newKey, i) => (
            <TranslationCard
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              newKey={newKey}
              selected={selected}
              allSelected={allSelected}
              toggleSelected={toggleSelected}
              i={i}
              projectBaseLanguage={projectBaseLanguage}
              projectBaseLanguageKeys={projectBaseLanguageKeys}
              isBaseLanguage={isBaseLanguage}
              handleBaseLanguageKeysValue={handleBaseLanguageKeysValue}
              lang={lang}
              edit={edit}
              keyToView={keyToView}
              handleDraftEditing={handleDraftEditing}
              setEdit={setEdit}
              projectLanguageKeys={projectLanguageKeys}
              edited={edited}
              setEdited={setEdited}
              setProjectLanguageKeys={setProjectLanguageKeys}
              setDisplayProjectLanguageKeys={setDisplayProjectLanguageKeys}
              originalKeys={originalKeys}
            />
          ))}
        {projectLanguageKeys &&
          !projectLanguageKeys.length &&
          projectBaseLanguageKeys &&
          !projectBaseLanguageKeys.length &&
          newProjectLanguageKeys &&
          !newProjectLanguageKeys.length &&
          !isLoading && (
            <Box sx={{ textAlign: "center" }}>
              <Lottie
                animationData={noElementsIllustration}
                style={{
                  width: "256px",
                  margin: "0 auto",
                }}
              />
              <Typography variant="h5">
                {t("translations.no-translations")}
              </Typography>
              <Typography variant="h5">
                {t("translations.add-translation")}
              </Typography>
              <Fab
                color="primary"
                aria-label="add"
                sx={{ mt: 2 }}
                onClick={() => handleNewTranslation()}
              >
                <AddIcon />
              </Fab>
            </Box>
          )}
      </Box>
    </>
  );
}

export default TranslationsList;
